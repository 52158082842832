define("ember-bootstrap/components/bs-modal/body", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="modal-body" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "S1+fXBgQ",
    "block": "[[[11,0],[24,0,\"modal-body\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-bootstrap/components/bs-modal/body.hbs",
    "isStrictMode": false
  });
  /**
  
   Modal body element used within [Components.Modal](Components.Modal.html) components. See there for examples.
  
   @class ModalBody
   @namespace Components
   @extends Glimmer.Component
   @public
   */


  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});